<template>
  <div class="services">
    <h2>Skills</h2>
    <Carousel
      :data="services"
      :pageDots="true"
      class="carousel"
      selectColour="white"
    />
    <div class="grid">
      <div v-for="(service, index) in services" :key="index" class="grid-item">
        <img :src="service.icon" alt="an icon" />
        <p class="subheading">{{ service.subheading }}</p>
        <span class="desc">{{ service.desc }}</span>
      </div>
    </div>
    <span id="rates"
      >Starting rate of £50 p/hour, depending on services needed. If you are a
      charity or NFP organization, please contact me to discuss pricing.
    </span>
  </div>
</template>

<script>
import Carousel from "../Carousel.vue";
export default {
  components: {
    Carousel,
  },
  data() {
    return {
      services: [
        {
          icon: require("@/assets/icons/SEO.webp"),
          subheading: "Search Engine Optimisation",
          desc: "Optimise your website, social media and ads to make sure your business is found when others search for you.",
        },
        {
          icon: require("@/assets/icons/SocialMediaMarketing.webp"),
          subheading: "Social Media Marketing",
          desc: "Develop and deliver a tailored marketing strategy whether its for Instagram, TikTok, LinkedIn, Facebook or YouTube.",
        },
        {
          icon: require("@/assets/icons/Copywriting.webp"),
          subheading: "Copywriting",
          desc: " Getting to grips with your brand, mimicking your brand voice to easily create captions and content that captures your audience whatever the media form.",
        },
        {
          icon: require("@/assets/icons/Blog.webp"),
          subheading: "Blog Writing",
          desc: "Producing scheduled blog posts about the key areas of your business, linking in trending topics and SEO.",
        },
        {
          icon: require("@/assets/icons/SocialMediaAds.webp"),
          subheading: "Social Media Ads (CTR and PPC)",
          desc: "Take your social media reach to the next level and purchase paid ads to push your brand further, reporting data and metrics back to you.",
        },
        {
          icon: require("@/assets/icons/SMSMarketing.webp"),
          subheading: "SMS and Email Marketing",
          desc: "Utilise even more methods of marketing by harnessing the power of manual and automated texts and emails using CRM systems like MailChimp and GoHighLevel. Create newsletters and lead responses. ",
        },
        {
          icon: require("@/assets/icons/PressRelease.webp"),
          subheading: "PR",
          desc: "Creation and distribution of press releases to share your business’s news with the media.",
        },
        {
          icon: require("@/assets/icons/PodcastIcon.webp"),
          subheading: "Podcasts",
          desc: "Creation and production of your podcast. From arranging guests and topic lists, to supporting the recording process, or editing and releasing your podcast to the necessary platforms.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.services {
  margin-top: 0;
  background: #258675;
  padding: 50px 150px 150px 150px;
}

#rates {
  display: block;
  text-align: center;
  font-style: italic;
  font-size: 14px;
  width: 100%;
  color: white;
  padding: 50px 0 0 0;
}

h2 {
  font-size: 50px;
  font-weight: 700;
  color: white;
  text-align: center;
}

.carousel {
  display: none;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 20px;

  .subheading {
    color: white;
    font-size: 20px;
    font-weight: 700;
  }

  .desc {
    font-size: 16px;
    font-weight: 400;
    color: white;
  }
}

.grid-item {
  width: 100%;
}

@media (max-width: 768px) {
  h2 {
    font-size: 40px;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  #rates {
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  .carousel {
    display: block;
  }

  .grid {
    display: none;
  }

  .services {
    padding: 40px 30px;
    text-align: center;
  }
}
</style>
